import { WebText } from "@sencrop/ui-web";
import React from "react";
import styled from "styled-components";
import {
  getSymbolFromCurrency,
  getPositionFromCurrency,
  Currency,
} from "../utils/locale";
import { usePage } from "../providers/PageProvider";
import { color } from "@sencrop/ui-components";

type PriceElementProps = {
  price?: number;
  pricePer?: string;
  textBeforePrice?: string;
  small?: boolean;
};

//This component it's about price
const PriceElement = ({
  price,
  pricePer,
  textBeforePrice,
  small = false,
}: PriceElementProps) => {
  const { localeConfig } = usePage();
  const { currency } = localeConfig;
  const currencySymbol = getSymbolFromCurrency(currency as Currency);
  const currencyPosition = getPositionFromCurrency(currency as Currency);
  return (
    <PriceContainer>
      <Price>
        {textBeforePrice && (
          <TextBeforePrice semibold>{textBeforePrice}</TextBeforePrice>
        )}
        {currencyPosition === "before" && (
          <CurrencyWrapper $small={small}>
            <WebText
              size={small ? "l" : "xxl"}
              color="dark"
              style={{ fontWeight: "600" }}
            >
              {currencySymbol}{" "}
            </WebText>
          </CurrencyWrapper>
        )}
        <WebText
          sizes={
            small
              ? ["xxl", "xxl", "xxl", "xxl"]
              : ["xxxl", "xxxl", "xxxl", "xxxl"]
          }
          color="dark"
          style={{ fontWeight: "800", margin: "0 2px" }}
        >
          {price}
        </WebText>
        <CurrencyWrapper $small={small}>
          <WebText
            size={small ? "l" : "xxl"}
            color="dark"
            style={{ fontWeight: "600" }}
          >
            {currencyPosition === "after" ? currencySymbol : ""}{" "}
            {pricePer ? `/ ${pricePer}` : ""}
          </WebText>
        </CurrencyWrapper>
      </Price>
    </PriceContainer>
  );
};

export default PriceElement;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextBeforePrice = styled(WebText)`
  color: ${color("text-primary:75")};
  padding: 5px;
`;

const Price = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CurrencyWrapper = styled.div<{ $small?: boolean }>`
  margin-left: ${(props) => (props.$small ? "3px" : "5px")};
  padding-top: ${(props) => (props.$small ? "5px" : "0")};
`;
