import { color, media } from "@sencrop/ui-components";
import { WebTitle, webtext } from "@sencrop/ui-web";
import React from "react";
import styled, { css } from "styled-components";
import ImageElement from "./ImageElement";
import LinkElement from "../containers/LinkElement";
import { IGatsbyImageData } from "gatsby-plugin-image";

type PartnerItemProps = {
  partner?: {
    linkUrl?: string;
    logo?: {
      gatsbyImageData?: IGatsbyImageData;
      title?: string;
    };
    title?: string;
    name?: string;
    description?: {
      childMarkdownRemark?: {
        html?: string;
      };
    };
    greenText?: string;
  };
  techSpecs?: boolean;
  encapsulatedLogo?: boolean;
};

const PartnerItem = ({
  partner,
  techSpecs,
  encapsulatedLogo,
}: PartnerItemProps) => {
  if (!partner) {
    return null;
  }

  return (
    <PartnerContainer
      as={partner.linkUrl ? "a" : "div"}
      href={partner.linkUrl ? partner.linkUrl : undefined}
    >
      {partner.logo && (
        <ImageContainer
          $encapsulatedLogo={encapsulatedLogo}
          $techSpecs={techSpecs}
        >
          <ImageElement
            gatsbyImageData={partner.logo.gatsbyImageData}
            style={{ width: "100%" }}
            alt={partner.logo.title || ""}
          />
        </ImageContainer>
      )}
      {partner.name && (
        <WebTitle
          as={techSpecs ? "p" : "h3"}
          size="s"
          color="primary"
          style={{ marginBottom: "4px" }}
        >
          {partner.name}
        </WebTitle>
      )}
      {partner.description?.childMarkdownRemark?.html && (
        <PartnerTextContent
          dangerouslySetInnerHTML={{
            __html: partner.description.childMarkdownRemark.html,
          }}
          style={{ whiteSpace: "pre-wrap" }}
        />
      )}
      {partner.linkUrl && partner.greenText && (
        <LinkElement>{partner.greenText}</LinkElement>
      )}
    </PartnerContainer>
  );
};

export default PartnerItem;

type PartnerContainerProps = {
  href?: string;
};

const PartnerContainer = styled.div<PartnerContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  ${(props) =>
    props.href &&
    css`
      h3 {
        transition: 0.4s;
      }
      &:hover,
      &:focus,
      &:active {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        h3 {
          color: ${color("text-primary")};
        }
      }
    `}
`;

const PartnerTextContent = styled.div`
  margin-bottom: 4px;
  p {
    color: ${color("text-secondary")};
    ${webtext("text", "s")};
  }
`;

type ImageContainerProps = {
  $techSpecs?: boolean;
  $encapsulatedLogo?: boolean;
};

const ImageContainer = styled.div<ImageContainerProps>`
  height: auto;
  width: 100%;
  margin-bottom: ${(props) =>
    props.$techSpecs || !props.$encapsulatedLogo ? "0.5rem" : "1.5rem"};
  max-height: ${(props) => (props.$techSpecs ? "4rem" : "6.25rem")};
  * {
    max-height: ${(props) =>
      props.$techSpecs ? "4rem" : "6.25rem"} !important;
  }
  ${(props) =>
    props.$encapsulatedLogo &&
    css`
      box-shadow: 0px 6px 11px rgba(85, 94, 104, 0.2);
      height: 100px;
      width: 100px;
      * {
        height: 100px;
        width: 100px;
      }
      border-radius: 11px;
      img {
        border-radius: 11px;
        background: white;
      }
    `}
  img {
    object-fit: contain !important;
    margin: auto;
  }
  ${media.greaterThan("tablet")`
    max-height: ${(props: ImageContainerProps) =>
      props.$techSpecs ? "5rem" : "7.5rem"};
    * {
      max-height: ${(props: ImageContainerProps) =>
        props.$techSpecs ? "5rem" : "7.5rem"} !important;
    }
    ${(props: ImageContainerProps) =>
      props.$encapsulatedLogo &&
      css`
        height: 120px;
        width: 120px;
        * {
          height: 120px;
          width: 120px;
        }
      `}
  `}
`;
