import { Color, color, Icon, media } from "@sencrop/ui-components";
import { WebText, WebTitle, webtext } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import ImageElement from "../components/ImageElement";
import LinkElement from "../containers/LinkElement";
import ButtonElement from "../containers/ButtonElement";
import PriceElement from "../components/PriceElement";
import { containerCss, contentCss } from "../helpers/style";
import { Link } from "../containers/LinkRenderer";

const COLOR_MAPPING: Record<string, Color> = {
  green: "branding-primary-variant",
  blue: "branding-secondary",
  lightBlue: "branding-secondary:10",
};

type BackgroundColorType = "green" | "blue" | "lightBlue";

const SectionBottom = ({
  sectionTitle,
  buttons,
  sectionText,
  textArguments: _arguments,
  image,
  backgroundColor = "lightBlue",
  link = undefined,
  price = undefined,
  textBeforePrice = undefined,
  promotion = false,
  embedded = false,
  showPrice,
}: GatsbyTypes.SectionBottomFragmentFragment & {
  link?: Link;
  price?: number;
  textBeforePrice?: string;
  promotion: boolean;
  embedded: boolean;
  backgroundColor?: BackgroundColorType;
  showPrice: boolean;
}) => {
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      $promotion={promotion}
      embedded={embedded}
    >
      <Content backgroundColor={backgroundColor} $promotion={promotion}>
        <VerticalAlign>
          <TextContainer $promotion={promotion}>
            {sectionTitle && (
              <WebTitle
                size="xl"
                as={promotion ? "p" : "h2"}
                style={{ paddingBottom: "0.5rem", whiteSpace: "pre-wrap" }}
                color={
                  backgroundColor === "lightBlue"
                    ? "branding-secondary"
                    : "light"
                }
              >
                {sectionTitle?.childMarkdownRemark?.rawMarkdownBody}
              </WebTitle>
            )}
            {sectionText?.childMarkdownRemark?.html && (
              <DescriptionContainer>
                <TextContent
                  $backgroundColor={backgroundColor}
                  dangerouslySetInnerHTML={{
                    __html: sectionText.childMarkdownRemark.html,
                  }}
                  style={{ whiteSpace: "pre-wrap" }}
                />
              </DescriptionContainer>
            )}
            {_arguments && (
              <ArgumentList $promotion={promotion}>
                {_arguments.map((argument, index) => (
                  <Argument key={index}>
                    <Icon
                      size={24}
                      name="check-small"
                      color={
                        backgroundColor === "lightBlue" ? "branding" : "light"
                      }
                    />
                    <WebText
                      color={
                        backgroundColor === "lightBlue" ? "branding" : "light"
                      }
                      semibold
                    >
                      {argument}
                    </WebText>
                  </Argument>
                ))}
              </ArgumentList>
            )}
            {(buttons || price) && (
              <InlineContainer>
                {price && showPrice && (
                  <PriceElement
                    price={price}
                    textBeforePrice={textBeforePrice}
                  />
                )}
                {buttons && (
                  <ButtonContainer>
                    {buttons.map((button, index) => (
                      <ButtonElement
                        key={index}
                        centered
                        link={button}
                        size="l"
                        color={
                          backgroundColor === "lightBlue"
                            ? "secondary"
                            : index === 0
                            ? "reverse"
                            : "reverse-outline"
                        }
                      >
                        {button?.label}
                      </ButtonElement>
                    ))}
                  </ButtonContainer>
                )}
              </InlineContainer>
            )}
            {link && (
              <LinkContainer>
                <LinkElement color="black" link={link}>
                  {link.label}
                </LinkElement>
              </LinkContainer>
            )}
          </TextContainer>
        </VerticalAlign>
        {image && (
          <ImageWrap $promotion={promotion}>
            <ImageContainer $promotion={promotion}>
              <ImageElement
                gatsbyImageData={image.gatsbyImageData}
                style={{ objectFit: "contain" }}
                alt={image.title || ""}
              />
            </ImageContainer>
          </ImageWrap>
        )}
      </Content>
    </Wrapper>
  );
};

export default SectionBottom;

export const fragment = graphql`
  fragment SectionBottomFragment on ContentfulSectionBottom {
    sectionTitle: title {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
    buttons {
      ...LinkFragment
    }
    sectionText {
      childMarkdownRemark {
        html
      }
    }
    textArguments: arguments
    image {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    backgroundColor
  }
`;

type WrapperProps = {
  $promotion: boolean;
  embedded: boolean;
  backgroundColor: BackgroundColorType;
};

const Wrapper = styled.div<WrapperProps>`
  ${(props) =>
    !props.$promotion &&
    css`
      background-color: ${color(COLOR_MAPPING[props.backgroundColor])};
    `}
  ${(props) =>
    props.$promotion &&
    css`
      margin-top: 2.5rem;
      margin-bottom: ${() => (props.embedded ? "0" : "2rem")};
    `}
  ${(props) =>
    props.$promotion &&
    !props.embedded &&
    css`
      padding-right: 1rem;
      padding-left: 1rem;
      ${media.greaterThan("tablet")`
        padding-right: 0;
        padding-left: 0;
      `};
    `}
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  ${media.greaterThan("tablet")`
    text-align: left;
    margin-top: ${(props: any) =>
      props.$promotion && props.embedded ? "4.5rem" : "3.5rem"};
    margin-bottom: ${(props: any) =>
      props.$promotion && !props.embedded ? "3.5rem" : "0"};
  `};
`;

type ContentProps = {
  $promotion: boolean;
  backgroundColor: BackgroundColorType;
};

const Content = styled.div<ContentProps>`
  ${containerCss}
  ${contentCss}
  ${(props) =>
    props.$promotion &&
    css`
      background-color: ${color(COLOR_MAPPING[props.backgroundColor])};
      border-radius: 6px;
    `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${media.greaterThan("tablet")`
    display: grid;
    grid-template-columns: auto ${(props: any) =>
      props.$promotion ? "480px" : "418px"};
    grid-template-columns: minmax(auto, calc(100% - ${(props: any) =>
      props.$promotion ? "480px" : "418px"})) 1fr;
    margin-top: 0;
    padding-top: 0;
    ${(props: any) =>
      props.$promotion &&
      css`
        padding-right: 0;
      `};
  `};
`;

const VerticalAlign = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.greaterThan("tablet")`
    padding-right: 1rem;
    justify-content: flex-start;
  `};
`;

type TextContainerProps = {
  $promotion: boolean;
};

const TextContainer = styled.div<TextContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  ${media.greaterThan("tablet")`
    margin-top: ${(props: any) => (props.$promotion ? "3.5rem" : "5rem")};
    margin-bottom: ${(props: any) => (props.$promotion ? "3.5rem" : "5rem")};
  `};
`;

type TextContentProps = {
  $backgroundColor: string;
};

const TextContent = styled.div<TextContentProps>`
  p {
    color: ${(props) =>
      props.$backgroundColor === "lightBlue"
        ? color("text-primary:75")
        : color("text-primary:10")};
    ${webtext("text", "m")};
  }
`;

type InlineContainerProps = {
  align?: "left";
};

export const InlineContainer = styled.div<InlineContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  ${media.greaterThan("tablet")`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    > *:first-child {
      margin-right: 1.5rem;
    }
    > *:nth-child(2) {
      margin-top: 0;
    }
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  *:only-child {
    margin: auto;
  }
  *:nth-child(2) {
    margin-left: 1.5rem;
  }
  ${media.greaterThan("tablet")`
    justify-content: flex-start;
    *:only-child {
      margin: 0;
    }
    *:nth-child(2) {
      margin-left: 1.5rem;
    }
  `}
`;

type ImageWrapProps = {
  $promotion: boolean;
};

const ImageWrap = styled.div<ImageWrapProps>`
  margin-top: 1rem;
  width: 100%;
  align-self: flex-end;
  ${media.greaterThan("tablet")`
    position: relative;
    height: 100%;
    margin-top: 0;
  `};
`;

type ImageContainerProps = {
  $promotion: boolean;
};

const ImageContainer = styled.div<ImageContainerProps>`
  ${media.greaterThan("tablet")`
    margin: 0;
    height: calc(100% + 3.5rem);
    width: 100%;
    position: absolute;
    bottom: 0;
    * {
      height: 100%;
    }
    img {
      object-fit: contain !important;
      object-position: right bottom !important;
    }
  `};
`;

const DescriptionContainer = styled.div`
  margin-bottom: 1rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 2rem;
  `}
`;

type ArgumentListProps = {
  $promotion: boolean;
};

const ArgumentList = styled.div<ArgumentListProps>`
  margin-top: 0.5rem;
  margin-bottom: ${(props) => (props.$promotion ? "6px" : "1rem")};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${media.greaterThan("tablet")`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
  `}
`;
const Argument = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 0.5rem;
  margin: 0 0.25rem;
  ${media.greaterThan("tablet")`
    display: flex;
    justify-content: center;
    padding-bottom: 0;
  `}
`;

const LinkContainer = styled.div`
  margin: 0 auto;
  ${media.greaterThan("tablet")`
    margin: 0;
  `}
`;
