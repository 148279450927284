import { Icon, media, color } from "@sencrop/ui-components";
import { webtext, WebTitle } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import PartnerItem from "../components/PartnerItem";
import LinkElement from "../containers/LinkElement";
import ButtonElement from "../containers/ButtonElement";
import { blockCss, containerCss, contentCss } from "../helpers/style";

const SectionPortfolio = ({
  title,
  description,
  partners = [],
  subhead,
  bigSubhead = false,
  seeMoreLabel,
  seeLessLabel,
  backgroundDesktop,
  encapsulatedLogos = false,
  link,
  techSpecs = false,
}: GatsbyTypes.SectionPortfolioFragmentFragment & {
  link: GatsbyTypes.Maybe<GatsbyTypes.LinkFragmentFragment>;
  techSpecs?: boolean;
}) => {
  const moreThanSixPartners = Boolean(partners.length > 6 && seeMoreLabel);

  const [open, setOpen] = useState(!moreThanSixPartners);

  const backgroundDesktopSrc = backgroundDesktop?.gatsbyImageData
    ? // @ts-ignore: Checking just above if gatsbyImageData is undefined ?
      getSrc(backgroundDesktop.gatsbyImageData)
    : undefined;

  return (
    <BackgroundWrapper>
      {backgroundDesktopSrc && <Background src={backgroundDesktopSrc} />}
      <Wrapper>
        <Header>
          {subhead && bigSubhead && (
            <BigSubHead>
              <WebTitle
                sizes={["xxl", "xxxxl", "xxxxl", "xxxxl"]}
                color="branding-secondary-light"
                aria-hidden={true}
              >
                {subhead}
              </WebTitle>
            </BigSubHead>
          )}
          {subhead && !bigSubhead && (
            <SubHead>
              <WebTitle size="s" color="branding-secondary">
                {subhead}
              </WebTitle>
            </SubHead>
          )}
          <TitleContent>
            <WebTitle as="h2" size="xl" color="primary">
              {title}
            </WebTitle>
          </TitleContent>
          {description?.childMarkdownRemark?.html && (
            <TextContent
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          )}
        </Header>
        {partners && (
          <PartnersContainer
            techSpecs={techSpecs}
            open={open}
            marginBottom={
              ((moreThanSixPartners && !open) ||
                (moreThanSixPartners && seeLessLabel && open) ||
                (techSpecs && link)) as boolean
            }
          >
            {partners.map((partner, index) => (
              <PartnerItem
                key={index}
                partner={partner}
                techSpecs={techSpecs}
                encapsulatedLogo={encapsulatedLogos}
              />
            ))}
          </PartnersContainer>
        )}
        {moreThanSixPartners && !open && (
          <LinkElement centered color="green" onClick={() => setOpen(true)}>
            {seeMoreLabel}
          </LinkElement>
        )}
        {moreThanSixPartners && seeLessLabel && open && (
          <LinkElement centered color="green" onClick={() => setOpen(false)}>
            {seeLessLabel}
          </LinkElement>
        )}
        {techSpecs && link && (
          <ButtonElement
            centered
            link={link}
            color="secondary"
            start={<Icon name="download-o" />}
            style={{ maxWidth: "90%" }}
          >
            {link.label}
          </ButtonElement>
        )}
      </Wrapper>
    </BackgroundWrapper>
  );
};

export default SectionPortfolio;

export const fragment = graphql`
  fragment SectionPortfolioFragment on ContentfulSectionPortfolio {
    title
    subhead
    bigSubhead
    description {
      childMarkdownRemark {
        html
      }
    }
    partners {
      logo {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        title
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      name
      greenText
      linkUrl
    }
    encapsulatedLogos
    seeMoreLabel
    seeLessLabel
    backgroundDesktop {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  ${blockCss}
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  ${media.greaterThan("tablet")`
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  `}
`;

const BackgroundWrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

const Background = styled.img`
  display: none;
  ${media.greaterThan("tablet")`
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

const Header = styled.div`
  max-width: 796px;
  margin: 0 auto;
`;

const TitleContent = styled.div`
  margin-bottom: 0.5rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 1rem;
  `}
`;

const BigSubHead = styled.div`
  word-wrap: break-word;
  margin: auto;
  position: relative;
  margin-top: -2.5rem;
  bottom: -1.5rem;
  ${media.greaterThan("tablet")`
    margin-top: -3.35rem;
    bottom: -2.35rem;
  `}
`;

const SubHead = styled.div`
  p {
    color: ${color("branding-secondary")};
  }
  margin-bottom: 0.5rem;
`;

const TextContent = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 3rem;
  `}

  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
`;

type PartnersContainerProps = {
  marginBottom: boolean;
  open: boolean;
  techSpecs?: boolean;
};

const PartnersContainer = styled.div<PartnersContainerProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1.5rem;
  margin-bottom: ${(props) => (props.marginBottom ? "1.5rem" : 0)};
  ${(props) =>
    !props.open &&
    css`
      * + * + * + * + * + * + * {
        display: none;
      }
    `}
  ${(props) => css`
    ${media.greaterThan("tablet")`
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: ${props.techSpecs ? "1.75rem" : "2rem"};
      margin-bottom: ${props.marginBottom ? "3rem" : 0};
    `}
  `};
`;
