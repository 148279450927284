import ReactDOM from "react-dom";

const VideoPortal = ({ ...props }) => {
  if (typeof document === "undefined" || !document) {
    return null;
  }
  const portalNode = document.getElementById("video-modal");
  if (!portalNode) {
    return null;
  }
  return ReactDOM.createPortal(props.children, portalNode);
};
export default VideoPortal;
