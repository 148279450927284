import { graphql } from "gatsby";
import React from "react";
import SectionBottom from "./SectionBottom";

const SectionPromotion = ({
  sectionTitle,
  button,
  sectionText,
  textArguments: _arguments,
  image,
  link,
  price,
  textBeforePrice,
  embedded = false,
  showPrice,
}: GatsbyTypes.SectionPromotionFragmentFragment & { embedded?: boolean }) => {
  const displayPrice = showPrice ?? true;
  return (
    <SectionBottom
      sectionTitle={sectionTitle}
      buttons={button ? [button] : undefined}
      sectionText={sectionText}
      textArguments={_arguments}
      image={image}
      link={link}
      price={price}
      textBeforePrice={textBeforePrice}
      promotion={true}
      embedded={embedded}
      backgroundColor={"lightBlue"}
      showPrice={displayPrice}
    />
  );
};

export default SectionPromotion;

export const fragment = graphql`
  fragment SectionPromotionFragment on ContentfulSectionPromotion {
    sectionTitle: title {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
    button {
      ...LinkFragment
    }
    sectionText {
      childMarkdownRemark {
        html
      }
    }
    textArguments: arguments
    image {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    price
    textBeforePrice
    link {
      ...LinkFragment
    }
    showPrice
  }
`;
